@import "./colors.scss";
.login-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: $light;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.login-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

label {
  color: black;
  font-weight: 600;
}

input {
  margin-bottom: 10px;
  height: 32px;
}

button {
  cursor: pointer;
}
