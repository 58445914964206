@import "./colors.scss";

.navbar {
  box-sizing: border-box;
  height: 15vh;
  width: 100%;
  background: #ecf0f1;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2px;
}

.navbar .imgcontainer {
  height: 100%;
  display: flex;
  align-items: center;
}

.navbar .imgcontainer img {
  height: 100%;
  mix-blend-mode: multiply;

  @include below-breakpoint($sm-bp) {
    height: auto;
    max-height: 100%;
    max-width: 196px;
  }
}

.linksgroup {
  display: flex;
  align-items: center;
  height: 100%;

  @include below-breakpoint($lg-bp) {
    display: none;
  }
}
.link {
  margin: 0px 1vw;
  font-weight: 600;
  color: $oxford-blue;
  cursor: pointer;
  outline: none;
  padding-bottom: 2px;
  text-decoration: none;
}
.link:hover {
  padding-bottom: 0px;
  border-bottom: 2px solid $oxford-blue;
}
.linkbreaker {
  padding: 0px 12px;
  height: 28px;
}
.linkbreaker .temp {
  width: 2px;
  height: 100%;
  background: $oxford-blue;
}

#navbar_popup {
  display: none;

  position: absolute;
  right: 8px;
  top: 16vh;
  // padding: 18px 0px;

  flex-direction: column;
  // gap: 8px;
  z-index: 29;

  background-color: #ecf0f1;
  border-radius: 8px;
  @include box-shadow;
  // height: 85vh;
  // width: 50vw;
  // position: absolute;
  // right: 0px;
  // bottom: 0px;
  // padding: 18px 0px;
  // background: rgb(245, 240, 240, 88%);
  // flex-direction: column;
  // justify-content: baseline;
  // align-items: center;
  // gap: 18px;
  @include below-breakpoint($sm-bp) {
    width: max-content;
  }
  @include above-breakpoint($lg-bp) {
    width: 0%;
  }
}

#navbar_popup .link {
  width: 256px;
  text-align: center;
  font-size: 18px;
  border-top: 2px solid #012047ff;
  padding: 8px 0px;
  // border: 2px solid $oxford-blue;
  // padding: 8px;
  // width: 256px;
  // text-align: center;
  // border-radius: 8px;

  @include above-breakpoint($lg-bp) {
    display: none;
  }
}
#navbar_popup .link:first-child {
  border-top: none;
}
// #navbar_popup .link:hover {
//   padding: 8px;
//   border-bottom: 2px solid $oxford-blue;
// }

.auth {
  position: absolute;
  width: 100%;
  height: 100vh;
  overflow: hidden;

  display: none;
  justify-content: center;
  align-items: center;

  background-color: rgba($color: $dark, $alpha: 0.7);

  z-index: 10;
}
.authbox {
  width: 384px;
  height: 384px;
  background: $light;
  border-radius: 8px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 18px;

  position: relative;

  background-color: rgba($color: $celtic-blue, $alpha: 0.9);
  @include box-shadow();
}
.authbox .close {
  position: absolute;
  top: 4px;
  right: 4px;
  cursor: pointer;
  font-size: 24px;
}
.authbox .small {
  padding-top: 18px;
  font-size: 12px;
  font-weight: 700;
  color: $light;
  cursor: pointer;
}

.profile {
  position: absolute;
  right: 8px;
  width: 270px;
  // height: 380px;
  // border-radius: 8px;
  // padding: 8px;

  // display: none;
  flex-direction: column;
  // gap: 2px;
  color: $oxford-blue;
  font-size: 14px;
}
.profile .section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 48px;
  cursor: default;

  padding: 8px;
  background: #ecf0f1;
}
.profile .section:hover {
  background: darken($color: #ecf0f1, $amount: 10%);
}

.profile .section:nth-child(1) {
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}
.profile .section-centered {
  justify-content: center;
}

.profile .section-last {
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}
.profile .section-btn-container:hover {
  background: darken($color: #ecf0f1, $amount: 10%);
}

.section-btn {
  background: $celtic-blue;
}

.profile .section-title {
  flex-direction: column;
  gap: 8px;
  padding: 18px 0px;
}
.profile .section-title span:nth-child(1) {
  font-size: 18px;
  font-weight: 600;
}
.profile .section-title span:nth-child(2) {
  font-size: 12px;
  font-weight: 500;
}

#signin_box,
#profile_box,
#change_pass {
  display: none;
}
