// AdminPanel.scss
.admin-panel {
  max-width: 1024px;
  margin: 0 auto;
  .action-bar {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    input[type="checkbox"] {
      margin-right: 5px;
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;

    th,
    td {
      border: 1px solid #ddd;
      padding: 8px;
      text-align: left;
    }

    th {
      background-color: #f2f2f2;
    }

    td {
      button {
        cursor: pointer;
      }
    }
  }
}
