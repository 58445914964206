$oxford-blue: #012047ff;
$shamrock-green: #44986fff;
$celtic-blue: #3d74cdff;
$dark-cyan: #438b92ff;
$kelly-green: #4ab135ff;
$light: #ecf0f1;
$dark: #00050b;

@mixin box-shadow() {
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.4);
}

$xs-bp: 0px;
$sm-bp: 576px;
$md-bp: 768px;
$lg-bp: 992px;
$xl-bp: 1200px;
$xxl-bp: 1400px;

// Function to create media queries
@mixin below-breakpoint($breakpoint) {
  @media (max-width: ($breakpoint - 1)) {
    @content;
  }
}

@mixin above-breakpoint($breakpoint) {
  @media (min-width: $breakpoint) {
    @content;
  }
}
