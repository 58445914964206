@import "./colors.scss";
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  // background-color: ;
  font-family: "Montserrat", sans-serif;
}

._error_box {
  box-sizing: border-box;

  position: absolute;
  background: #cb4335;
  font-size: 16px;
  color: $light;
  font-weight: 500;
  border-radius: 8px;

  display: none;
  align-items: center;
  justify-content: center;

  text-align: center;

  min-width: 260px;

  padding: 8px 24px;

  top: 18px;
  left: 50%;
  transform: translateX(-50%);

  z-index: 10;
  cursor: pointer;
  @include box-shadow();
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 8px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $shamrock-green;
  border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: darken($color: $shamrock-green, $amount: 12);
}

// input:not(.styled) {
//   border: none;
//   background: rgba($color: $light, $alpha: 0.8);
//   font-size: 14px;
//   padding: 2px 8px;
//   outline: none;
//   border-radius: 4px;
// }

// button {
//   border: none;
//   background: rgba($color: $shamrock-green, $alpha: 1);
//   color: white;
//   font-size: 14px;
//   font-weight: 700;
//   padding: 8px 24px;
//   border-radius: 4px;
//   cursor: pointer;
//   transition: 0.2s ease all;
//   @include box-shadow();
// }
// button:hover {
//   transform: translateY(-10%);
// }
// button:active {
//   transform: translateY(0%);
// }

// .iconButton {
//   font-size: 24px;
//   border: none;
//   background: transparent;
//   cursor: pointer;
//   background: $celtic-blue;
//   color: $light;
//   padding: 8px;
//   width: 48px;
//   height: 48px;
//   border-radius: 100%;

//   transition: 0.3s ease all;

//   @include box-shadow;
// }
// .iconButton:active {
//   transform: translateY(-10%);
// }

// /* Input Designs */
// .styled:not(.clear) {
//   border: none;
//   background: transparent;
//   font-size: 14px;
//   padding: 2px 8px;
//   outline: none;
//   border-bottom: 2px solid #012047ff;
// }

/* Responsive */

// Show only when screen is SMALLER than the breakpoint
.below-xs {
  display: none;

  @include below-breakpoint($xs-bp) {
    display: block;
  }
}

.below-sm {
  display: none;

  @include below-breakpoint($sm-bp) {
    display: block;
  }
}

.below-md {
  display: none;

  @include below-breakpoint($md-bp) {
    display: block;
  }
}

.below-lg {
  display: none;

  @include below-breakpoint($lg-bp) {
    display: block;
  }
}

.below-xl {
  display: none;

  @include below-breakpoint($xl-bp) {
    display: block;
  }
}

// Show only when screen is GREATER than the breakpoint
.after-xs {
  display: none;

  @include above-breakpoint($xs-bp) {
    display: block;
  }
}

.after-sm {
  display: none;

  @include above-breakpoint($sm-bp) {
    display: block;
  }
}

.after-md {
  display: none;

  @include above-breakpoint($md-bp) {
    display: block;
  }
}

.after-lg {
  display: none;

  @include above-breakpoint($lg-bp) {
    display: block;
  }
}

.after-xl {
  display: none;

  @include above-breakpoint($xl-bp) {
    display: block;
  }
}
